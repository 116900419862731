import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "./../../Layout/MainLayout";
import "./style.css";
import axios from "axios";
import BASEURL from "../../Config/global";
import CustomButton from "../../Components/CustomButton";
import { Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import CustomTable from "../../Components/CustomTable";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { crossIcon } from "../../Assets/svg";
import { useDispatch } from "react-redux";
import { userLogout } from "../../Store/Slices/APIs";

const Account = () => {

    const dispatch = useDispatch();

    const [userData, setUserData] = useState([]);
    const [invoicesData, setInvoicesData] = useState([]);
    const [paymentDetail, setPaymentDetail] = useState([]);
    const [succesModal, setSuccesModal] = useState(false);
    const [paymentDecline, setPaymentDecline] = useState(false);
    const [modal, setModal] = useState(false);
    const [error, setError] = useState({ error: false, text: "" });
    const [platformMessageModal, setPlatformMessageModal] = useState(false);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(userLogout())
    }, [])

    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        if (currentUser.status === "sub_user") {
            navigate('/settings')
        }
        getProfile(currentUser.id);
        getInvoices();
        getPaymentDetail();
    }, []);

    const getProfile = async (id) => {
        try {
            const response = await axios.get(
                `${BASEURL}/api/user/profile/${id}`
            );
            if (!response.data.error) {
                setUserData(response?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getInvoices = async (id) => {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(
                `${BASEURL}/api/user/get_invoices/${currentUser.id}`
                // `${BASEURL}/api/user/get_invoices/687`
            );
            if (!response.data.error) {
                setInvoicesData(response?.data?.data.reverse());
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getPaymentDetail = async (id) => {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        try {
            const response = await axios.get(
                `${BASEURL}/api/user/get_cards/${currentUser.id}`
                // `${BASEURL}/api/user/get_cards/691`
            );
            if (!response.data.error) {
                // console.log(response?.data?.data)
                const arr = [];
                arr.push(response?.data?.data)
                setPaymentDetail(response?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const headers = [
        {
            key: 'id',
            title: 'S. No.',
        },
        {
            key: 'number',
            title: 'Invoice Number',
        },
        {
            key: 'effectdate',
            title: 'Effective Date',
        },
        {
            key: 'amount',
            title: 'Amount',
        },
        {
            key: 'status',
            title: 'Status',
        },
        {
            key: 'download',
            title: 'Download',
        },
    ]

    const paymentDetailHeaders = [
        {
            key: 'brand',
            title: 'Type',
        },
        {
            key: 'country',
            title: 'Country',
        },
        {
            key: 'expMonth',
            title: 'Expire Month',
        },
        {
            key: 'expyear',
            title: 'Expire Year',
        },
        {
            key: "last4",
            title: "Last 4 Digit's",
        },
        {
            key: "update_payment_method",
            title: "Update Payment Method",
        },
    ]

    const stripe = useStripe()
    const elements = useElements()

    const signUpAPi = async () => {
        try {
            setLoader(true)
            const currentUser = JSON.parse(localStorage.getItem("user"));
            const response = await axios.get(
                `${BASEURL}/api/user/detach_card/${currentUser.id}`
            );
            if (!response.data.error) {
                const customerId = currentUser.stripe_customer_id;

                if (!stripe || !elements) {
                    // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }

                const cardElement = elements.getElement(CardElement);

                // Create a PaymentMethod and attach it to the customer
                const { paymentMethod, error } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                });

                if (error) {
                    setModal(false);
                    setLoader(false);
                    setPaymentDecline(true);
                    setTimeout(() => {
                        setPaymentDecline(false);
                    }, 2500);
                } else {
                    // Attach the PaymentMethod to the customer
                    // console.log(paymentMethod.id);
                    setModal(false);
                    setLoader(false);
                    const obj = {
                        customerId: customerId,
                        paymentMethodId: paymentMethod.id
                    }
                    const response = await axios.post(`${BASEURL}/api/user/attach_card`, obj);
                    setSuccesModal(true);
                    getPaymentDetail();
                    setTimeout(() => {
                        setSuccesModal(false);
                    }, 2500);

                }
            } else {
                setLoader(false);
            }

        } catch (error) {
            setLoader(false);
            console.error('Error:', error);
        }
    }

    const checkSubscription = () => {
        const currentUser = localStorage.getItem("user");
        const parsedUser = JSON.parse(currentUser);
        if (parsedUser.platform === 'mobile') {
            setPlatformMessageModal(true);
        } else {
            navigate('/upgrade-subscription');
        }
    }

    return (
        <>
            <MainLayout>
                <div className="row flex-column mb-5">
                    <div className='col-12'>
                        <Tabs
                            defaultActiveKey="plandetails"
                            id="uncontrolled-tab-example"
                            className="customTabs mb-5"
                        >
                            <Tab eventKey="plandetails" title="Plan Details">
                                <div className='coupon-payment-wrapper p-4 mt-5 text-center'>
                                    <h2 className="pageTitle mb-3">Account Details</h2>
                                    <p className='lightColor fs-4 mb-2'>{userData[0]?.package_detail[0]?.title}</p>
                                    <p className='lightColor fs-5 mb-2'>{userData[0]?.package_detail[0]?.description} </p>
                                    <p className='lightColor fs-6 mb-2'>Price ${userData[0]?.package_detail[0]?.price}  USD</p>
                                    {/* <p className='lightColor fs-6 mb-2'>Subscription Type: <span className="text-capitalize">{userData[0]?.package_detail[0]?.type}</span></p> */}

                                    {/* {
                                        userData[0]?.package_detail[0]?.type === "coupons" &&
                                        <p className='lightColor fs-6 mb-2'>Valid Untill: {(userData[0]?.package_detail[0]?.coupon_detail[0]?.expires === "undefined" || userData[0]?.package_detail[0]?.coupon_detail[0]?.expires === null) ? "Unlimited" : userData[0]?.package_detail[0]?.coupon_detail[0]?.expires}</p>
                                    } */}
                                    {
                                        (userData[0]?.package_detail[0]?.type === "coupons" && userData[0]?.package_detail[0]?.coupon_detail[0]?.trial_period !== "0") && <p className='lightColor fs-6 mb-2'>Trial Period: {userData[0]?.package_detail[0]?.coupon_detail[0]?.trial_period} Days </p>
                                    }
                                    <div className='payNow text-center mt-4'>
                                        <CustomButton
                                            variant="primaryButton"
                                            text="Change Plan"
                                            onClick={checkSubscription}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="invoices" title="Invoices">
                                <div className="">
                                    <CustomTable headers={headers}>
                                        <tbody>
                                            {
                                                invoicesData.length > 0 && invoicesData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.number}</td>
                                                        <td>{item.effected_at}</td>
                                                        <td>${item.amount_paid}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.invoice_pdf != '' && <Link to={item.invoice_pdf} target="_blank" download>Download</Link>}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </CustomTable>
                                </div>
                            </Tab>
                            <Tab eventKey="paymentmethod" title="Payment Method">
                                <div className="">
                                    <CustomTable headers={paymentDetailHeaders}>
                                        <tbody>
                                            {
                                                paymentDetail.length > 0 && paymentDetail.map((item, index) => (
                                                    <tr key={index}>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td>{item.brand}</td>
                                                        <td>{item.country}</td>
                                                        <td>{item.exp_month}</td>
                                                        <td>{item.exp_year}</td>
                                                        <td>{item.card_last4}</td>
                                                        <td>
                                                            <CustomButton
                                                                variant="primaryButton"
                                                                text="Update Card"
                                                                onClick={() => { setModal(true); setLoader(false) }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </CustomTable>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </MainLayout>

            <Modal
                show={modal}
                centered
                className="payment-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="pb-4 px-3 w-100 text-center">
                        <div className="text-end pb-4">
                            <button
                                className="closeButton notButton ms-auto"
                                onClick={() => { setModal(false); }}
                            >
                                <img src={crossIcon} alt="" />
                            </button>
                        </div>
                        <div className='cardBox my-4'>
                            <CardElement />
                        </div>
                        {
                            loader ? (

                                <button class="primaryButton customButton">
                                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                                </button>
                            ) : (
                                <CustomButton
                                    variant="primaryButton"
                                    text="Save"
                                    onClick={signUpAPi}
                                />
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={succesModal}
                centered
                className="success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">Payment Method Updated!</h2>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={paymentDecline}
                centered
                className="success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">Card Declined!</h2>
                    </div>
                </Modal.Body>
            </Modal>

            {/* success modal */}
            <Modal
                show={platformMessageModal}
                centered
                className="soundModal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="text-end">
                        <button
                            className="closeButton notButton ms-auto"
                            onClick={() => setPlatformMessageModal(false)}
                        >
                            <img src={crossIcon} alt="" />
                        </button>
                    </div>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">To edit your payment details, please use your Relax Scofa Mobile App.</h2>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Account