import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Placeholder, logo } from "../../Assets/images";

import {
  MenuHome,
  MenuSound,
  MenuMeditation,
  MenuMoon,
  MenuArticle,
  ArticlesIcon,
  StoreIcon,
  FindDoctorsIcon,
  CheckSymptomsIcon,
  MyMixes,
  crossIcon,
} from "../../Assets/svg";

import "./style.css";
import { Howl } from "howler";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import BASEURL from "../../Config/global";
import QrCodeGenerator from "../../Components/MenuBar/QR-code";
import { hideUrlForCountry } from "../../Util/global_functions";

export const Menubar = (props) => {
  const primaryInformation = useRef(null)

  const location = useLocation();

  const [informationData, setInformationData] = useState([]);
  const [showHideTray, setShowHideTray] = useState(false);
  const [desktopLogo, setDesktopLogo] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [loader, setLoader] = useState(true);
  const [qrIsVisible, setQrIsVisible] = useState(false);
  const userCountry = JSON.parse(localStorage.getItem("user_country"));

  useEffect(() => {
    const sound = new Howl({
      src: [require(`./../../../src/Assets/audio/silent.mp3`)], // Update the path accordingly
      autoplay: true,
      html5: true,
      autoUnlock: true,
      preload: true,
      volume: 0,
      mute: true,
      autoSuspend: false,
    });

    // Play the sound
    sound.play();

    // Clean up
    return () => {
      sound.unload();
    };
  }, []);

  const menu = [
    {
      text: "Home",
      link: "/home",
      path: "home",
      icon: MenuHome,
    },
    {
      text: "Sounds & Music",
      link: "/sounds",
      path: "sounds",
      icon: MenuSound,
    },
    {
      text: "Relaxation",
      link: "/relaxation",
      path: "relaxation",
      icon: MenuMeditation,
    },
    {
      text: "Stories",
      link: "/stories",
      path: "stories",
      icon: MenuMoon,
    },
    {
      text: "Audio Articles",
      link: "/articles",
      path: "articles",
      icon: MenuArticle,
    },
  ];

  useEffect(() => {
    async function informationTray() {
      setLoader(true);
      const currentUser = localStorage.getItem("user");
      const parsedUser = JSON.parse(currentUser);
      // console.log(parsedUser)
      let endPoint;
      if (parsedUser.status === "coupon" || parsedUser.status === "coupon_sub_user") {
        endPoint = "coupon-user";
      }
      if (parsedUser.status === "primary" || parsedUser.status === "sub_user") {
        endPoint = "subscription-user";
      }
      // const endPoint = (parsedUser.status === "coupon") ? "coupon-user" : (parsedUser.status === "primary") && "subscription-user"
      // const endPoint = "coupon-user";
      // console.log(endPoint)
      try {
        const response = await axios.get(`${BASEURL}/api/user/${endPoint}/${parsedUser.id}/`);
        // console.log(response.data.data)
        if (response.data.error != true) {
          setInformationData(response.data.data[0]);
          if (response.data.data[0]?.pri_header_logo_info) {
            setHeaderLogo(response.data.data[0]?.pri_header_logo_info);
          } else if (response.data.data[0]?.sec_header_logo_info) {
            setHeaderLogo(response.data.data[0]?.sec_header_logo_info);
          } else {
            setHeaderLogo(Placeholder);
          }
          if (response.data.data[0]?.pri_desktop_logo) {
            setDesktopLogo(response.data.data[0]?.pri_desktop_logo);
          } else if (response.data.data[0]?.sec_desktop_logo) {
            setDesktopLogo(response.data.data[0]?.sec_desktop_logo);
          } else {
            setDesktopLogo(Placeholder);
          }
          setLoader(false);
          // console.log(response.data)
          // console.log(desktopLogo)
        } else {
          setLoader(false);
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    informationTray();
  }, [])


  if (primaryInformation?.current != null) {
    primaryInformation.current.innerHTML = ''
    const div = document.createElement("div");
    primaryInformation?.current.appendChild(div);
    if (informationData?.pri_html_info_text) {
      div?.insertAdjacentHTML("beforebegin", informationData?.pri_html_info_text)
    } else {
      div?.insertAdjacentHTML("beforebegin", informationData?.sec_html_info_text)
    }
  }

  return (
    <div className={`menubar ${props?.menuClass}`}>
      <Link className="logo" to="/home">
        <img src={logo} alt="Logo" />
      </Link>
      <ul className="list-unstyled mb-0">
        <li className="menubar-li">
          <Link
            className={
              location.pathname.includes("home")
                ? "menuLink active"
                : "menuLink"
            }
            to={"/home"}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.56 15.69"><defs>
            </defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M.44,7.41,7.12.92a1.64,1.64,0,0,1,2.32,0l6.68,6.49M14.38,5.86v8.52a.87.87,0,0,1-.87.87H11.76a.87.87,0,0,1-.87-.87V12.64a1.74,1.74,0,0,0-1.74-1.75H7.41a1.75,1.75,0,0,0-1.75,1.75v1.74a.87.87,0,0,1-.87.87H3.05a.87.87,0,0,1-.87-.87V5.86" /></g></g></svg>
            <span className="menuLinkText">Home</span>
          </Link>
        </li>
        <li className="menubar-li">
          <Link
            className={
              location.pathname.includes("sounds")
                ? "menuLink active"
                : "menuLink"
            }
            to={"/sounds"}
          >
            <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.05 68.05"><g id="Layer_23"><path class="cls-5" fill="none" strokeWidth={'4.54px'} strokeLinejoin="round" strokeLinecap="round" stroke="#fff" d="m65.78,20.42l-45.37,13.61m45.37,16.9V11.9c0-6.45-5.86-11.07-11.68-9.22l-27.22,8.66c-3.84,1.22-6.47,4.97-6.47,9.22v36.15m45.37-5.78c0,5.01-4.06,9.07-9.07,9.07s-9.07-4.06-9.07-9.07,4.06-9.07,9.07-9.07,9.07,4.06,9.07,9.07Zm-45.37,5.78c0,5.01-4.06,9.07-9.07,9.07s-9.07-4.06-9.07-9.07,4.06-9.07,9.07-9.07,9.07,4.06,9.07,9.07Z" /></g></svg>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.23 14.4"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="none" strokeWidth={'4.54px'} strokeLinejoin="round" strokeLinecap="round" stroke="#fff" class="cls-1" d="M13.92,4.32,4.32,7.2m9.6,4.8V2.52A1.94,1.94,0,0,0,11.45.57L5.69,2.4A2,2,0,0,0,4.32,4.35V12m13.44,0a1.92,1.92,0,1,1-1.92-1.92A1.92,1.92,0,0,1,17.76,12ZM4.32,12A1.92,1.92,0,1,1,2.4,10.08,1.92,1.92,0,0,1,4.32,12Z" /></g></g></svg> */}
            {/* <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.05 68.05"><g id="Layer_23"><path class="cls-1" d="m65.78,20.42l-45.37,13.61m45.37,16.9V11.9c0-6.45-5.86-11.07-11.68-9.22l-27.22,8.66c-3.84,1.22-6.47,4.97-6.47,9.22v36.15m45.37-5.78c0,5.01-4.06,9.07-9.07,9.07s-9.07-4.06-9.07-9.07,4.06-9.07,9.07-9.07,9.07,4.06,9.07,9.07Zm-45.37,5.78c0,5.01-4.06,9.07-9.07,9.07s-9.07-4.06-9.07-9.07,4.06-9.07,9.07-9.07,9.07,4.06,9.07,9.07Z"/></g></svg> */}
            <span className="menuLinkText">Sounds & Music</span>
          </Link>
        </li>
        <li className="menubar-li">
          <Link
            className={
              location.pathname.includes("relaxation")
                ? "menuLink active"
                : "menuLink"
            }
            to={"/relaxation"}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.23 16.32"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M14.88,3.36V13M12,5.28V11m5.76-5.76V11M.48,4.32V12M6.24.48V15.84M9.12,3.36V13M3.36,2.4V13.92" /></g></g></svg>
            <span className="menuLinkText">Relaxation</span>
          </Link>
        </li>
        <li className="menubar-li">
          <Link
            className={
              location.pathname.includes("stories")
                ? "menuLink active"
                : "menuLink"
            }
            to={"/stories"}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.36 18.23"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M14.56,15.34a1,1,0,0,0-.47-1.71,8.76,8.76,0,0,1-5.9-3.8,9.2,9.2,0,0,1-.9-8A1,1,0,0,0,6,.56,9.11,9.11,0,0,0,.48,8.84c0,5,4.28,9,8.68,8.91A8.2,8.2,0,0,0,14.56,15.34Z" /></g></g></svg>
            <span className="menuLinkText">Stories</span>
          </Link>
        </li>
        <li className="menubar-li">
          <Link
            className={
              location.pathname.includes("articles")
                ? "menuLink active"
                : "menuLink"
            }
            to={"/articles"}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.15 17.19"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-2" d="M1,.72A.32.32,0,0,0,.72,1V16.16a.33.33,0,0,0,.32.32H13.11a.33.33,0,0,0,.32-.32V1a.32.32,0,0,0-.32-.32ZM13.11,17.19H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H13.11a1,1,0,0,1,1,1V16.16a1,1,0,0,1-1,1" /><path class="cls-2" d="M2.43,6.11H6.86V3.42H2.43Zm4.47.72H2.38a.68.68,0,0,1-.67-.68V3.37a.67.67,0,0,1,.67-.67H6.9a.67.67,0,0,1,.68.67V6.15a.68.68,0,0,1-.68.68" /><path class="cls-2" d="M12.08,9.38H2.3A.36.36,0,0,1,1.94,9a.36.36,0,0,1,.36-.36h9.78a.36.36,0,0,1,.36.36.36.36,0,0,1-.36.36" /><path class="cls-2" d="M12.08,11.94H2.3a.36.36,0,1,1,0-.72h9.78a.36.36,0,0,1,0,.72" /><path class="cls-2" d="M12.08,14.5H2.3a.36.36,0,0,1,0-.72h9.78a.36.36,0,0,1,0,.72" /><path class="cls-2" d="M12.08,3.84h-3a.36.36,0,0,1-.36-.36.36.36,0,0,1,.36-.36h3a.36.36,0,0,1,.36.36.36.36,0,0,1-.36.36" /><path class="cls-2" d="M12.08,6.4h-3a.36.36,0,0,1,0-.72h3a.36.36,0,1,1,0,.72" /></g></g></svg>
            <span className="menuLinkText">Audio Articles</span>
          </Link>
        </li>
      </ul>
      <ul className="list-unstyled d-none d-md-block m-0">
        <li className="menubar-li">
          <Link
            className={
              location.pathname.includes("save-mixers")
                ? "menuLink active"
                : "menuLink"
            }
            to={"/save-mixers"}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.29 25.31"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Sounds"><rect class="cls-mix1" x="7.63" y="8.63" width="21.16" height="16.18" /><path class="cls-mix2" d="M18.21,13.8a3,3,0,0,1,2.57-1.19,2.83,2.83,0,0,1,1.94,1.71c.69,1.68-.66,3.77-3.32,6.12a1.85,1.85,0,0,1-2.42,0c-2.65-2.35-4-4.41-3.28-6.08a2.88,2.88,0,0,1,1.94-1.78A3.05,3.05,0,0,1,18.21,13.8Z" /><polyline class="cls-mix2" points="4.06 20.68 4.06 4.46 23.15 4.46" /><polyline class="cls-mix2" points="0.5 16.72 0.5 0.5 19.59 0.5" /></g></g></svg>
            <span className="menuLinkText">My Mixes</span>
          </Link>
        </li>
        {
          hideUrlForCountry(userCountry) && (
            <>
              <hr className="menuSeperator d-none d-md-block" />
              <li className="menubar-li">
                <Link
                  className="menuLink"
                  to={"https://scofa.com/articles/"}
                  target="_blank"
                >
                  <img src={ArticlesIcon} className="menuIcon" />
                  {/* <ArticlesIcon /> */}
                  {/* </span> */}
                  <span className="menuLinkText">Articles</span>
                </Link>
              </li>
              <li className="menubar-li">
                <Link
                  className="menuLink"
                  to={"https://store.scofa.com/"}
                  target="_blank"
                >
                  {/* <span className="menuIcon">
              <StoreIcon />
            </span> */}
                  <img src={StoreIcon} className="menuIcon" />
                  <span className="menuLinkText">Sleep Store</span>
                </Link>
              </li>
              <li className="menubar-li">
                <Link
                  className="menuLink"
                  to={"https://scofa.com/check-sleep-disorder-symptoms/"}
                  target="_blank"
                >
                  <img src={CheckSymptomsIcon} className="menuIcon" />
                  {/* <span className="menuIcon">
              <CheckSymptomsIcon />
            </span> */}
                  <span className="menuLinkText">Check Symptoms</span>
                </Link>
              </li>

              <li className="menubar-li">
                <Link className="menuLink" to={"https://scofa.com/search-for-sleep-provider/"} target="_blank">
                  {/* <span className="menuIcon">
              <FindDoctorsIcon />
            </span> */}
                  <img src={FindDoctorsIcon} className="menuIcon" />
                  <span className="menuLinkText">Sleep Doctors</span>
                </Link>
              </li>
            </>
          )
        }
        <hr className="menuSeperator d-none d-md-block" />
        <li className="menubar-li">
          <QrCodeGenerator
            qrIsVisible={qrIsVisible}
            handleQrCode={(value) => {
              if (value) {
                setShowHideTray(false)
              }
              setQrIsVisible(value)
            }}
          />
        </li>
        <li className="information-tray-card py-0">
          {loader ? <div className="skeleton"></div>
            : <div className={`information-tray-image ${showHideTray && 'show'}`} onClick={() => {
              if (!showHideTray) {
                setQrIsVisible(false)
              }
              setShowHideTray(!showHideTray)
            }}>
              <img src={desktopLogo} />
            </div>
          }
        </li>
      </ul>
      {
        (window.innerWidth > 991) && (
          <div className={`information-tray d-flex flex-column ${showHideTray && 'show'}`}>
            <div className="header position-relative">
              <button
                className="closeButton notButton ms-auto d-flex p-3"
                onClick={() => { setShowHideTray(false) }}
              >
                <img src={crossIcon} alt="" />
              </button>
              <img src={headerLogo} className="w-100 info-header-image" />
            </div>
            <div className="scrollbar">
              <div className="p-3">
                <div className="information-tray-data w-100" ref={primaryInformation}></div>
              </div>
            </div>
          </div>
        )
      }

    </div>
  );
};

