import { LockBadge } from "../../Assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { playAudio } from "../../Store/Slices/AudioSlice";
import { checkSoundPlayerOrMixes, clearAllMixer, clearAllSound, pauseMyMixes, playMusic, playSound, removeMusic, removeSound, selectedSound, startSounds } from "./../../Store/Slices/SoundPlayerSlice";
import "./style.css";
import BASEURL from "../../Config/global";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { activeUser } from "../../Store/Slices/APIs";

const RoundAudio = (props) => {

  const sounds = useSelector((state) => state.soundPlayer.sounds);
  const music = useSelector((state) => state.soundPlayer.music);
  const checkSoundPlayerOrMixesState = useSelector((state) => state.soundPlayer.checkSoundPlayerOrMixesState);
  const isMixers = useSelector((state) => state.soundPlayer.isMixes);

  const myRef = useRef(null);
  const [soundsData, setSoundsData] = useState([]);
  const [listner, setListner] = useState(false);

  const [addClass, setAddClass] = useState(false);
  const [addMusicClass, setAddMusicClass] = useState(false);
  const dispatch = useDispatch();


  const dispatchPlaySound = (item) => {
    dispatch(checkSoundPlayerOrMixes(true));
    if (item.soundcategory === 164) {
      if (addMusicClass) {
        dispatch(removeMusic());
        setAddMusicClass(false)
      } else {
        dispatch(activeUser());
        dispatch(playMusic(item));
      }
    } else {
      dispatch(activeUser());
      dispatch(playSound(item));
    }
  };

  const dispatchRemoveSound = (item) => {
    dispatch(removeSound(item))
  }

  useEffect(() => {
    const soundExists = sounds.some((sound) => sound.id === props.item.id);
    setAddClass(soundExists)
  }, [sounds])

  useEffect(() => {
    if (music.id == props.item.id) {
      setAddMusicClass(true)
    } else {
      setAddMusicClass(false)
    }
  }, [music])

  useEffect(() => {
    const soundExists = sounds.some((sound) => sound.id === props.item.id);
    setAddClass(soundExists)
  }, [props.clicklistner])

  return (
    <div ref={myRef} className={`${(addClass || addMusicClass) && 'gradient'} roundAudioWrapper`}>
      <div
        className={`roundAudio ${props.indexes}`}
        onClick={() => {
          { addClass ? dispatchRemoveSound(props.item) : dispatchPlaySound(props?.item) }
        }}
      >
        <div className="imageWrapper">
          <img
            src={`${props.item.tumbnail ?  props.item.tumbnail :  props.item.thumbnail}`}
            alt="Sound Thumbnail"
          />
        </div>
        <p>{props.item.title}</p>
        {props.item.premium && (
          <span className="premiumBadge">
            <LockBadge />
          </span>
        )}
      </div>
    </div>
  );
};

export default RoundAudio;
