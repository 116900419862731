import MainLayout from "../../Layout/MainLayout";
import { useEffect, useRef, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";
import BASEURL from "../../Config/global";
import axios from "axios";
import { BackButtonNew, PencilEdit } from "../../Assets/svg";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { avatar } from "../../Assets/images";
import CountrySelector from "../../Components/CountrySelect";
import { COUNTRIES_LIST } from "../../Config/data";



const EditProfile = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [updateFormData, setUpdateFormData] = useState({});
  const [error, setError] = useState({ error: false, text: "" });
  const [loading, setLoading] = useState(false);
  const [succesModal, setSuccesModal] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    getProfile(user.id);
  }, []);

  const getProfile = async (id) => {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/profile/${id}`
      );
      if (!response.data.error) {
        console.log(response?.data);
        setFormData(response?.data?.data[0]);
        // setProfileImage(response?.data?.data[0].user_image);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event) => {
    setError({ error: false, text: "" });
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setUpdateFormData({ ...updateFormData, [name]: value });
  };

  const handleChangeCountry = (val) => {
    setError({ error: false, text: "" });
    setFormData({ ...formData, country: val });
    setUpdateFormData({ ...updateFormData, country: val });
  };

  const handler = (event) => {
    const { name, value } = event.target;
    if (event.key === 'Backspace') {
      if (value.length == 3) {
        setFormData({ ...formData, [name]: value.slice(0, 2) });
        setUpdateFormData({ ...updateFormData, [name]: value.slice(0, 2) });
      } else if (value.length == 6) {
        setFormData({ ...formData, [name]: value.slice(0, 5) });
        setUpdateFormData({ ...updateFormData, [name]: value.slice(0, 5) });
      } else if (value.length <= 10 || value.length == '') {
        setFormData({ ...formData, [name]: value });
        setUpdateFormData({ ...updateFormData, [name]: value });
      }
    }
  };

  const changeMe = (e) => {
    const { name, value } = e.target;

    const sanitizedInput = value.replace(/[^0-9/]/g, '');
    const truncatedInput = sanitizedInput.slice(0, 10);
    const matches = truncatedInput.match(/^(\d{0,2})\/?(\d{0,2})\/?(\d{0,})$/);
    if (matches) {
      const [, month, day, year] = matches;
      const isValidMonth = month === '' || (parseInt(month, 10) <= 12);
      const isValidDay = day === '' || (parseInt(day, 10) <= 31);
      const isValidYear = year === '' || (!isNaN(year));
      if (isValidMonth && isValidDay && isValidYear) {
        const formattedInput = [month, day, year].filter(Boolean).join('/');
        // setFormData({ ...formData, 'date_of_birth': formattedInput });
        setFormData({ ...formData, 'date_of_birth': formattedInput });

        setUpdateFormData({ ...updateFormData, 'date_of_birth': formattedInput });

      } else {
        console.log("Invalid date");
      }
    } else {
      console.log("Invalid date format");
    }
  };

  const validDateDateFormat = () => {
    if (formData?.date_of_birth?.length > 0 && formData.date_of_birth.length < 10) {
      return false
    }

    return true
  };
  const profileUpdated = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validDateDateFormat()) {
      setLoading(false);
      setError({
        error: true,
        text: "Invalid date format. Please enter a valid date in mm/dd/yyyy format.",
      });
      return
    }
    if (!formData.first_name || formData.first_name === "") {
      setLoading(false);
      setError({
        error: true,
        text: "First name should be not empty. Please Fill first name",
      });
      return
    }

    if (!formData.last_name || formData.last_name === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Last name should be not empty. Please Fill last name",
      });
      return
    }
    // if (!formData.zip || formData.zip === "") {
    //   setLoading(false);
    //   setError({
    //     error: true,
    //     text: "Country should be not empty. Please Fill Country",
    //   });
    //   return
    // }

    if (!formData.email || formData.email === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Email should be not empty. Please Fill Email",
      });
      return
    }

    let selectedCountry = null
    if (!formData.country || formData.country === "") {
      setLoading(false);
      setError({
        error: true,
        text: "Country should be not empty. Please Fill Country",
      });
      return
    } else {
      let checkCountryForUSA = formData.country?.toLowerCase()
      if (checkCountryForUSA === "usa" || checkCountryForUSA === "us" || checkCountryForUSA === "u.s.a" || checkCountryForUSA === "america" || checkCountryForUSA === "united states") {
        selectedCountry = "United States of America"
      } else {
        selectedCountry = formData.country;
      }
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const formDataToSend = new FormData();
    let updatedForCountry={...updateFormData, country:selectedCountry}
    for (const key in updatedForCountry) {
      formDataToSend.append(key, updatedForCountry[key]);
    }
    // if (profileImageFile) {
    //   formDataToSend.append("user_image", profileImageFile);
    // }
    if (Object.keys(updateFormData).length !== 0) {
      try {
        const response = await axios.patch(
          `${BASEURL}/api/user/profile/${user.id}`,
          formDataToSend
        );
        if (!response.data.error) {
          localStorage.setItem('user_country', JSON.stringify(formData.country))
          setLoading(false);
          setSuccesModal(true);
          setTimeout(() => {
            setSuccesModal(false);
            navigate('/settings')
          }, 2500);
        } else {
          console.log("error 123")
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setLoading(false);
      setError({ error: true, text: "No field is updated!" });
    }

  }

  const handleProfileChange = (event) => {
    const file = event.target.files[0];
    setProfileImageFile(file);
    if (file === null || file === "undefined") {
      setProfileImage(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setProfileImage(reader.result);
      };
    }
  };


  return (
    <>
      <MainLayout>

        <div className="go-back mb-5 d-inline-block">
          <Link to={`/settings`} className='rotate d-flex align-items-center text-decoration-none'>
            <img src={BackButtonNew} className="mw-100 w-22" />
            <h1 className='mb-0 pageTitle'>Edit Profile</h1>
          </Link>
        </div>

        <form>
          <div className="row">
            {/* <div className="col-lg-12 mb-3">

              <div className="d-flex align-items-center gap-3">
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  className="d-none"
                  required
                  name="banner"
                  onChange={handleProfileChange}
                />
                <label className="profile-image-wrapper" htmlFor="image">
                  <img src={PencilEdit} alt="" className="profile-icon" />
                  <img src={(profileImage && profileImage.includes("/no_image.png")) ? avatar : profileImage} alt="" className="profileImage" />
                </label>
              </div>
             <h2 className="font-16 lh-16 my-5 fw-normal mb-3 mt-3">Update your profile picture</h2>
            </div> */}
            <div className="col-lg-3">
              <CustomInput
                label="First Name"
                required
                id="fname"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="first_name"
                value={formData.first_name || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-3">
              <CustomInput
                label="Last Name"
                required
                id="lname"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="last_name"
                value={formData.last_name || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-lg-6">
              <CustomInput
                label="Email"
                required
                id="email"
                type="email"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="email"
                value={formData.email || ""}
                onChange={handleChange}

              />
            </div>
            {/* <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="City"
                id="city"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="city"
                value={formData.city || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="State"
                id="state"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="state"
                value={formData.state || ""}
                onChange={handleChange}
              />
            </div> */}
            <div className="col-xl-3 col-lg-6">
              {/* <CustomInput
                label="Zip"
                id="zip"
                type="text"
                labelClass="mainLabel"
                inputClass="mainInput"
                name="zip"
                value={formData.zip || ""}
                onChange={handleChange}
              /> */}
              <CountrySelector
                value={formData.country || ""}
                handleChange={handleChangeCountry}
                inputClass={''}
                name={'Country'}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="Birthdate"
                id="date"
                type="text"
                placeholder="mm/dd/yyyy"
                labelClass="mainLabel"
                inputClass="mainInput place-holder-white"
                name="date_of_birth"
                value={formData?.date_of_birth || ""}
                onChange={changeMe}
                onKeyDown={handler}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <CustomInput
                label="Phone"
                id="phone"
                type="number"
                placeholder=""
                labelClass="mainLabel"
                inputClass="mainInput"
                name="phone_number"
                value={formData?.phone_number || ""}
                onChange={handleChange}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="inputWrapper">
                <CustomInput
                  label="Change Password"
                  type="password"
                  required
                  id="password"
                  labelClass="mainLabel"
                  inputClass="mainInput"
                  name="password"
                  value={formData.password || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            {error.error && (
              <div className="col-12">
                <p className="smallText lightColor">{error.text}</p>
              </div>
            )}
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
              {
                loading ? (
                  <button class="primaryButton customButton">
                    <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (
                    <CustomButton
                      variant="primaryButton"
                      text="Update Profile"
                      onClick={profileUpdated}
                    />
                  )
              }
            </div>
          </div>
        </form>

        {/* success modal */}
        <Modal
          show={succesModal}
          centered
          className="success-modal"
          backdrop="static"
        >
          <Modal.Body>
            <div className="p-5">
              <h2 className="font-16 lh-16 my-5 fw-normal text-center">Profile Updated</h2>
            </div>
          </Modal.Body>
        </Modal>
      </MainLayout>
    </>
  );
};

export default EditProfile;
