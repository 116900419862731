import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { getAccessToken } from "../Util/authHeader";
// import MainAudioPlayer from "../Components/MainAudioPlayer";
// import MultiAudioPlayer from "../Components/MultiAudioPlayer";
import MultiAudioPlayerrr from "../Components/MultiAudioPlayerrr";
import IphoneMultiAudioPlayerrr from "../Components/IphoneMultiAudioPlayerrr";
import { useEffect, useState } from "react";

import Main from "../Screens/Main/";

import Login from "../Screens/Auth/Login";
import Signup from "../Screens/Auth/Signup";
import ForgotPassword from "../Screens/Auth/ForgotPassword";
import Verification from "../Screens/Auth/Verification";
import ResetPassword from "../Screens/Auth/ResetPassword";


import Home from "../Screens/Home";
import Stories from "../Screens/Stories";
import Relaxation from "../Screens/Relaxation";
import Articles from "../Screens/Articles";
import Sounds from "../Screens/Sounds";
// import Sounds from "../Screens/Sounds2";
import Settings from "../Screens/Settings";
import Upgrade from "../Screens/Upgrade";
import { isIOS, isMobileSafari, isSafari } from "react-device-detect";
import TestMultiAudioPlayer from "../Components/TestMultiAudioPlayer";
import SaveShareMixExternal from "../Screens/SaveShareMixExternal";
import SaveMixShare from "../Screens/SaveMixShare";
import Specials from "../Screens/Specials";
import { PaymentForm } from "../Screens/Upgrade/PaymentForm";
import AudioDetail from "../Screens/AudioDetail";
import HouseholdMember from "../Screens/Settings/HouseHoldMembers";
import CouponSubscription from "../Screens/CouponSubscription";
import ExternalRoutes from "./ExternalRoutes";
import PrivacyPolicy from "../Screens/PrivaryPolicy";
import Account from "../Screens/Account";
import UpgradeSubscription from "../Screens/UpgradeSubscription";
import { UpgradePayment } from "../Screens/UpgradeSubscription/UpgradePayment";
import TermsConditions from "../Screens/TermsConditions";
import MoreOptions from "../Screens/MoreOptions";
import PageNotFound from "../Screens/PageNotFound";
import EditProfile from "../Screens/EditProfile";
import VideoPlayer from "../Screens/VideoPlayer";
import FreeTrial from "../Screens/FreeTrial";
import Redirection from "../Screens/Redirection";
import DownloadApp from "../Screens/DownloadApp";
import RedirectToPromo from "../Screens/RedirectToPromo";

const WebRoutes = () => {
  const token = getAccessToken();
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route
          path="/home"
          element={<Navigate to={token ? "/home" : "/login"} />}
        /> */}
          <Route
            path="/login"
            element={
              <ProtectedRoutes>
                <Login />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/signup"
            element={
              <ProtectedRoutes>
                <Signup />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/download-app"
            element={
              <ProtectedRoutes>
                <DownloadApp />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <ProtectedRoutes>
                <ForgotPassword />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/verification"
            element={
              <ProtectedRoutes>
                <Verification />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <ProtectedRoutes>
                <ResetPassword />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/audio-detail/:type/:id"
            element={
              <PrivateRoutes>
                <AudioDetail />
              </PrivateRoutes>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoutes>
                <Home />
              </PrivateRoutes>
            }
          />
          <Route
            path="/stories"
            element={
              <PrivateRoutes>
                <Stories />
              </PrivateRoutes>
            }
          />
          <Route
            path="/relaxation"
            element={
              <PrivateRoutes>
                <Relaxation />
              </PrivateRoutes>
            }
          />
          <Route
            path="/articles"
            element={
              <PrivateRoutes>
                <Articles />
              </PrivateRoutes>
            }
          />
          <Route
            path="/sounds"
            element={
              <PrivateRoutes>
                <Sounds />
              </PrivateRoutes>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoutes>
                <Settings />
              </PrivateRoutes>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <PrivateRoutes>
                <EditProfile />
              </PrivateRoutes>
            }
          />
          <Route
            path="/household-member"
            element={
              <PrivateRoutes>
                <HouseholdMember />
              </PrivateRoutes>
            }
          />
          <Route
            path="/save-mixers"
            element={
              <PrivateRoutes>
                <SaveMixShare />
              </PrivateRoutes>
            }
          />
          <Route
            path="/share.mix/:id/:id"
            element={
              <SaveShareMixExternal />
            }
          />
          <Route
            path="/specials"
            element={
              <PrivateRoutes>
                <Specials />
              </PrivateRoutes>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivateRoutes>
                <PrivacyPolicy />
              </PrivateRoutes>
            }
          />
          <Route
            path="/terms-of-service"
            element={
              <PrivateRoutes>
                <TermsConditions />
              </PrivateRoutes>
            }
          />
          <Route
            path="/upgrade-subscription"
            element={
              <PrivateRoutes>
                <UpgradeSubscription />
              </PrivateRoutes>
            }
          />
          <Route
            path="/upgrade-subscription/:id"
            element={
              <PrivateRoutes>
                <UpgradePayment />
              </PrivateRoutes>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoutes>
                <Account />
              </PrivateRoutes>
            }
          />
          <Route
            path="/more-options"
            element={
              <PrivateRoutes>
                <MoreOptions />
              </PrivateRoutes>
            }
          />
          <Route
            path="/subscription"
            element={
              <ProtectedRoutes>
                <Upgrade />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/package-details/:id"
            element={
              <ProtectedRoutes>
                <PaymentForm />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/subscription-specials"
            element={
              <Navigate to="/promo" />
            }
          />
      
          
          <Route
            path="/promo"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />
          <Route
            path="/promoint"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />
          <Route
            path="/subscription-specials/:couponId"
            element={
              <RedirectToPromo />
            }
          />
          <Route
            path="/promoint/:couponId"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />
          <Route
            path="/promo/:couponId"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />
          <Route
            path="/promo"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />
          <Route
            path="/promoint"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />
          <Route
            path="/promoint/:couponId"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />
          <Route
            path="/promo/:couponId"
            element={
              <ExternalRoutes>
                <CouponSubscription />
              </ExternalRoutes>
            }
          />

          <Route
            path="/relax.video.player"
            element={
              <ExternalRoutes>
                <VideoPlayer />
              </ExternalRoutes>
            }
          />
          <Route
            path="/freetrial"
            element={
              <ExternalRoutes>
                <FreeTrial />
              </ExternalRoutes>
            }
          />


          <Route
            path="/qr-code"
            element={
              <Redirection />
            }
          />

          {/* <Route path="*" element={<Main />} /> */}


          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />


        </Routes>
        <MainAudioPlayerWrapper />
      </BrowserRouter>
    </>
  );
};

const MainAudioPlayerWrapper = () => {
  const token = getAccessToken();
  const location = useLocation();
  const [tokenStatus, setTokenStatus] = useState(false);
  const [iphoneBrowser, setIphoneBrowser] = useState(false);

  useEffect(() => {
    const newToken = getAccessToken();
    const isMainRoute = location.pathname === "/";
    if (newToken && location.pathname != "/") {
      setTokenStatus(true);
    } else {
      setTokenStatus(false);
    }
  }, [location, token]);

  useEffect(() => {
    if (isMobileSafari || isSafari || isIOS) {
      setIphoneBrowser(true)
    }
  }, [location, token]);

  useEffect(() => {
    if (location.pathname.includes("/share.mix/")) {
      document.body.classList.add('share-mixer-external-layout');
    }
  }, [location, token]);




  return tokenStatus && iphoneBrowser ? <IphoneMultiAudioPlayerrr /> : tokenStatus ? <MultiAudioPlayerrr /> : null;
};

export default WebRoutes;
