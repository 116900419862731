import React, { useEffect, useState } from 'react'
import { PaymentForm } from '../Upgrade/PaymentForm'
import { Link, useNavigate } from 'react-router-dom'

import "../Main/style.css";
import CustomButton from '../../Components/CustomButton';
import CustomInput from '../../Components/CustomInput';
import { Modal, Spinner } from 'react-bootstrap';
import BASEURL from '../../Config/global';
import axios from 'axios';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Signup from '../Auth/Signup';
import { crossIcon } from '../../Assets/svg';
import "./style.css"
import { logo } from '../../Assets/images';
import { useParams } from 'react-router'
import { useSelector } from 'react-redux';

const CouponSubscription = () => {
  const [formData, setFormData] = useState({});
  const [loginError, setLoginError] = useState({ error: false, text: "" });
  const [loading, setLoading] = useState(true);
  const [paymentModalIsInProgress, setPaymentModalIsInProgress] = useState(false);

  
  const [id, setId] = useState(1);

  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState();
  const [succesModal, setSuccesModal] = useState(false);
  const [paymentDecline, setPaymentDecline] = useState(false);
  const [SignUpForm, setSignUpForm] = useState(false);
  const [showPaymentUI, setShowPaymentUI] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [couponDetail, setCouponDetail] = useState([]);
  const [couponError, setCouponError] = useState({ error: false, text: "" });
  const [couponPaymentError, setCouponPaymentError] = useState({ error: false, text: "" });
  const [loginModal, setLoginModal] = useState(false);
  // const params = new URLSearchParams(window.location.search);
  const [newCouponData, setNewCouponData] = useState({});
  const { couponId } = useParams();


  useEffect(() => {
    if (couponId) {
      handleSpecialSubscription(couponId)
    } else {
      setLoading(false)
      setSignUpForm(true)
    }
  }, [])
  const handleSpecialSubscription = async (code) => {
    setLoading(true)
    const API = `${BASEURL}/api/user/coupon/${code}`
    const coupon_response = await axios.get(API);
    if (coupon_response.data.status === 200) {
      const coupon_response_data = coupon_response.data.data[0];
      setNewCouponData(coupon_response_data)
      const isPromoUser = window.sessionStorage.getItem("isPromoUser")
      if (!isPromoUser) {
        setLoading(false)
        setSignUpForm(true)
      }

    } else {
      if (coupon_response.data.error) {
        setCouponError({
          error: true,
          text: coupon_response.data.message
        })
        setCouponModal(true)
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    const checkUser = JSON.parse(localStorage.getItem("user"));
    const isPromoUser = window.sessionStorage.getItem("isPromoUser")
    
    if (checkUser?.id ) {
      if (token) {
        navigate('/home')
      }else if (isPromoUser){
        setLoading(false)
        setSignUpForm(false);
        setCouponModal(true);
      }
    }
  }, [])

  const handleClick = async (e) => {
    if (formData.coupon) {
      setCouponError({ error: false, text: "" });
      getCoupon(formData.coupon);
    }
    if (newCouponData && newCouponData?.code) {
      setCouponError({ error: false, text: "" });
      getCoupon(newCouponData?.code);
    }
    if (couponError?.error) {
      navigate('/freetrial')
    }
  }

  const getCoupon = async (code) => {
    setLoading(true);
    let parseData = JSON.parse(localStorage.getItem("user"));
    const userEmail = parseData.email;
    try {
      const response = await axios.post(
        `${BASEURL}/api/user/get_coupon`,
        {
          "email": userEmail,
          "code": code
        }
      );
      if (!response.data.error) {
        // console.log(response.data.data[0])
        if (response.data.data[0].price === "0" || response.data.data[0].price === "0.00") {
          setCouponData(response.data.data[0]);
          // setLoginModal(true);
          await AfterPaymentApi(response.data.data[0].id).then((result) => {
            setCouponModal(false);
            setLoading(false);
            setLoginModal(false);
            navigate('/download-app');
          });
        } else {
          console.log(response);
          setCouponData(response.data.data[0]);
          setCouponDetail(response.data.data[0].coupon_detail[0]);
          setCouponModal(false);
          setLoading(false);
        }
      } else {
        setCouponError({ error: true, text: "coupon invalid" });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setCouponError({ error: true, text: "coupon invalid" });
      // Handle the error appropriately, e.g., display an error message to the user.
    }
  }

  const stripe = useStripe()
  const elements = useElements()

  async function PackageDetails() {
    try {
      const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
      if (response.data.error != true) {
        console.log(response?.data?.data[0])
        setPaymentData(response?.data?.data[0])
        // console.log(response)
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // PackageDetails();
  }, [])

  // payment form integration 

  async function signUpAPi() {
    setLoading(true);
    setPaymentModalIsInProgress(true)
    setCouponPaymentError({ error: false, text: "" });

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const cardElement = elements.getElement(CardElement);

      // Create a PaymentMethod and attach it to the customer
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      
      if (error) {
        setLoading(false);
        setPaymentModalIsInProgress(false)
        if (error.message.includes('Invalid API Key provided:')) {
          setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
        } else {
          setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
          // setCouponPaymentError({ error: true, text: error.message });
          console.error(error.message);
          setPaymentDecline(true);
          setTimeout(() => {
            setPaymentDecline(false);
          }, 2500);
        }
        // setCouponPaymentError({ error: true, text: error.message });
      } else {
        // Attach the PaymentMethod to the customer
        // const obj = {
        //   customerId: customerId,
        //   paymentMethodId: paymentMethod.id
        // }

        let parseData = JSON.parse(localStorage.getItem("user"));
        const userEmail = parseData.email;
        const productId = paymentData?.package_price_id;
        const planId = couponData?.plan_id;
        // const trial_period = couponDetail?.trial_period;
        const formData = new FormData();
        formData.append('email', userEmail);
        formData.append('plan_id', planId);
        // formData.append('trial_period', trial_period);
        formData.append('platform', "web");
        formData.append('paymentMethodId', paymentMethod.id);
        formData.append('coupon_code', 'coupon');

        const response = await axios.post(`${BASEURL}/api/user/customersubscription/`, formData);
        // const customerId = response?.data?.data?.customer_id;
        if (!response.data.error) {
          // setLoading(false);
          // setSuccesModal(true);
         await AfterPaymentApi()
          // setTimeout(() => {
          //   setSuccesModal(false);
          //   navigate('/login')
          // }, 2500);
        } else {
          setLoading(false);
          setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle the error appropriately, e.g., display an error message to the user.
    }
  }

  const AfterPaymentApi = async (product_id) => {
    // console.log(product_id, "-=-=-=-")
    let parseData = JSON.parse(localStorage.getItem("user"));
    const userId = parseData.id;
    const productId = couponData?.id;
    console.log(productId)
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", userId);
    formDataToSend.append("package_id", product_id === undefined ? productId : product_id);
    try {
      const response = await axios.post(
        `${BASEURL}/api/user/package_done`,
        formDataToSend
      );
      setLoading(false)
      if (!response.data.error) {
        navigate('/download-app')
      }
    } catch (error) {
      console.error(error);
    }
  }

  const closePopup = () => {
    setSignUpForm(false);
    setShowPaymentUI(true);
    setCouponModal(true);
  }

  if (loading && !paymentModalIsInProgress) {
    return (
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100vh'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
}
  return (
    <div>
      <section className="mainPremium">
        <div className="container-fluid">

          <div className="row flex-column">
            <div className='col-12'>
              {
                (!couponModal && !SignUpForm) &&
                <div className='coupon-payment-wrapper p-4 text-center'>
                  <h2 className="pageTitle mb-3">Enter Payment Method</h2>
                  <p className='lightColor fs-4 mb-1'>{couponDetail?.title}</p>
                  {/* <p className='lightColor fs-5 mb-2'>{couponDetail?.description} (Free 14 Day Trial)</p> */}
                  <p className='lightColor fs-5 mb-2'>{couponDetail?.description}</p>
                  {couponDetail?.trial_period !== "0" && <p className='lightColor fs-6 mb-2'>Free Trial Period: {couponDetail?.trial_period} Days </p>}
                  <p className='lightColor fs-6 mb-2'>Payment Type: {couponDetail?.type}</p>
                  <p className='lightColor fs-6 mb-2 '>Price : ${couponDetail?.price} USD</p>
                  {/* <p className='lightColor fs-6 mb-2 '>Coupon Subscription ${couponDetail?.price} USD</p> */}
                  {/* <p className='lightColor fs-6 mb-2'>Valid Untill: {couponDetail?.expires === "undefined" ? "Unlimited" : couponDetail?.expires}</p> */}
                  <div className='cardBox my-4'>
                    <CardElement />
                  </div>
                  {
                    couponPaymentError.error && <p className='my-2 text-danger'>{couponPaymentError.text}</p>
                  }
                  {
                    loading ? (
                      <div className='payNow text-center mt-4'>
                        <button class="primaryButton customButton">
                          <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                        </button>
                      </div>
                    )
                      : (
                        <div className='payNow text-center mt-4'>
                          <CustomButton
                            variant="primaryButton"
                            text="Save now"
                            onClick={signUpAPi}
                          />
                        </div>
                      )
                  }
                </div>
              }
            </div>
          </div>

          <Modal
            show={couponModal}
            centered
            className="coupon-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className='py-5'>
                <img src={logo} alt="Logo" className='logo' />
                {/* <p className='lightColor fs-5 my-4 text-center'>Please enter coupon code</p> */}
                {
                  couponId ?
                    <div>
                      <p className='font-16 fw-bold text-center mt-5'>Promo Code: {newCouponData?.code?.toUpperCase()}</p>
                      <p className='fw-light text-center small'>{newCouponData?.code_description}</p>
                    </div>
                    :
                    <div className='field-wrapper mt-5'>
                      <CustomInput
                        placeholder={''}
                        id="coupon"
                        type="text"
                        labelClass="mainLabel"
                        inputClass="mainInput text-center"
                        label="Coupon Code"
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            coupon: event.target.value,
                          });
                        }}
                      />
                    </div>
                }

                {couponError.error == true && (
                  <p className="smallText lightColor mb-0 text-center mt-2">{couponError.text}</p>
                )}
                {
                  loading ? (
                    <div className='payNow text-center mt-5'>
                      <button class="primaryButton customButton">
                        <div class="spinner-border spinner-border-sm text-dark" role="status"></div>
                      </button>
                    </div>
                  )
                    : (
                      <div className='payNow text-center mt-5'>
                        <CustomButton
                          variant="primaryButton"
                          text={couponId ? "OK" : "Submit"}
                          onClick={handleClick}
                        />
                      </div>
                    )
                }
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={SignUpForm}
            centered
            className="signform-modal"
            backdrop="static"
          >
            <Modal.Body>
              <Signup coupon={"coupon"} closeCouponSignup={closePopup} />
            </Modal.Body>
          </Modal>

          {/* success modal */}
          <Modal
            show={succesModal}
            centered
            className="success-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="p-5">
                <h2 className="font-16 lh-16 my-5 fw-normal text-center">Saved Successfully, <br />  Please Login</h2>
              </div>
            </Modal.Body>
          </Modal>

          {/* decline modal */}
          <Modal
            show={paymentDecline}
            centered
            className="success-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="text-end ml-auto">
                {/* <button
                            className="closeButton notButton ms-auto"
                            onClick={() => setPaymentDecline(false)}
                        >
                            <img src={crossIcon} alt="" />
                        </button> */}
              </div>
              <div className="p-5">
                <h2 className="font-16 lh-16 my-5 fw-normal text-center">Payment Declined!</h2>
              </div>
            </Modal.Body>
          </Modal>

          {/* success modal */}
          <Modal
            show={loginModal}
            centered
            className="success-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="p-5">
                <h2 className="font-16 lh-16 my-5 fw-normal text-center">Thank you! Please Login</h2>
              </div>
            </Modal.Body>
          </Modal>

        </div>
      </section>
    </div>
  )
}

export default CouponSubscription