import React, { useEffect, useRef, useState } from 'react'
import { logo } from '../../Assets/images';
import BASEURL from '../../Config/global';
import { PreviewPauseButton, PreviewPlayButton, crossIcon } from '../../Assets/svg';
import './style.css';
import { Howl, Howler } from "howler";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { isIOS, isMobileSafari, isSafari } from 'react-device-detect';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import BASEURLFrontend from '../../Config/urls';

const SaveShareMixExternal = () => {
    const navigate = useNavigate();

    // const [audio] = useState(new Audio(url));

    const [howlList, setHowlList] = useState([]);
    const [mixerData, setMixerData] = useState([]);
    // const [soundData, setSoundsData] = useState([]);
    const [timer, setTimer] = useState(null);
    const [pauseTimer, setPauseTimer] = useState(null);
    const [rangeValue, setRangeValue] = useState(50);
    const [audioData, setAudioData] = useState([]);
    const [soundData, setSoundData] = useState([]);
    const [play, setPlay] = useState(true);
    // const [decryptedText, setDecryptedText] = useState('');
    const audio = useRef(null);
    const audiotypes = ["relaxation", "articles", "stories"];

    let { id } = useParams();

    useEffect(() => {

        let decrypted = '';
        // const decryptText = () => {
        //     const key = 'secret-key'; // Replace with your secret key
        //     const decryptedId = id.split("$").join('/');
        //     console.log(decryptedId)
        //     const decrypted = CryptoJS.AES.decrypt(decryptedId, key);
        //     decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        // };
        // decryptText();

        const decryptText = () => {
          const key = 'secret-key'; // Replace with your secret key
          const decryptedId = id?.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
          const decryptedText = CryptoJS.AES.decrypt(decryptedId, key);
          decrypted = decryptedText?.toString(CryptoJS.enc.Utf8);
        //   setHousehold(decrypted);
        };
        decryptText();

        // console.log(decrypted)
        // async function fetchSoundData() {
        //     try {
        //         const response = await axios.get(`${BASEURL}/api/sounds/`);
        //         if (response.data.error != true) {
        //             const sortData = response?.data?.data.filter((item, index) => {
        //                 if (isMobileSafari || isSafari || isIOS) {
        //                     if (item.ios_patch && item.ios_sound) {
        //                         return item;
        //                     }
        //                 } else {
        //                     if (item.audio) {
        //                         return item;
        //                     }
        //                 }
        //             })
        //             setSoundsData(sortData);
        //         } else {
        //             console.log(response.data.message);
        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }

        async function fetchData() {
            try {
                const response = await axios.get(`${BASEURL}/api/sounds/sounds_mixture/${decrypted}`);
                setMixerData(response.data.data[0]['sounds_list']);
            } catch (error) {
                console.error(error);
                navigate("/signup");
                // window.location.reload(false)
            }
        }
        // fetchSoundData();
        fetchData();
    }, []);

    // const decryptText = () => {
    //     const key = 'secret-key'; // Replace with your secret key
    //     const decrypted = CryptoJS.AES.decrypt(encryptedText, key);
    //     setDecryptedText(decrypted.toString(CryptoJS.enc.Utf8));
    // };

    useEffect(() => {
        let audios = []
        let sounds = []
        mixerData && mixerData.filter((item, index) => {
            if (item.type != "sounds") {
                audios.push(item)
            } else {
                sounds.push(item)
            }
        })
        setAudioData(audios);
        setSoundData(sounds);
        // console.log(audioData)
        // console.log(soundData)
    }, [mixerData])

    useEffect(() => {
        const duplicateHowlList = [];
        // console.log(mixerData)
        mixerData && mixerData.forEach((item, index) => {
            const howl = new Howl({
                src: [ item.audio],
                loop: true,
                autoplay: false,
                webAudio: true, // Use Web Audio API if supported
                html5: true, // Use HTML5 audio if supported
                autoUnlock: true,
                preload: true,
                volume: 0.5,
                autoSuspend: false,
                onload: function () {
                    this.loaded = true;
                },
            });
            howl.info = item;
            howl.loaded = false;
            duplicateHowlList.push({ howl });
            setHowlList(duplicateHowlList);
        })
    }, [mixerData])

    useEffect(() => {
        if (timer === 0) {
            setTimer(null)
            setPauseTimer(null);
            stopsound();
            setPlay(false);
        }
        if (!timer) return;
        const intervalId = setInterval(() => {
            setTimer(timer - 1);
            setPauseTimer(timer - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it

    }, [timer])

    const stopsound = () => {
        mixerData.forEach((item, i) => {
            // howlList[i].howl.pause()
            document.getElementById(`audio${i}`).pause();
        })
    }

    const startsound = () => {
        if (play) {
            setPlay(!play);
            if (!pauseTimer) {
                setTimer(120);
                setPauseTimer(120);
            } else {
                setTimer(pauseTimer);
            }
            mixerData.forEach((item, i) => {
                document.getElementById(`audio${i}`).play();
            });
        } else {
            setPlay(!play);
            setPauseTimer(timer);
            setTimer(null)
            stopsound();
        }
    }

    return (
        <div style={{ height: '100vh' }}>
            <div style={{ width: '85%' }} className='h-100 m-auto d-flex flex-column share-mix-external-layout'>
                <div className="logo d-flex align-items-center justify-content-center pt-5">
                    <img src={logo} alt="Logo" style={{ width: '38%' }} />
                </div>
                <div className='d-flex flex-column justify-content-center'>
                    <div className='text-center my-5'>
                        {/* <h4 className='text-uppercase fw-light' style={{ fontSize: '19px' }}>Welcome!</h4> */}
                        <p className='fw-light fs-5 m-auto px-4' style={{ lineHeight: '1.5' }}>Enjoy this Mix Sample, shared with you.</p>
                    </div>
                    {/* <div className='d-flex justify-content-center mb-4'>
                        <button style={{ backgroundColor: '#a4a2b0' }} className='w-50 px-4 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={startsound}>
                            <img src={PreviewPlayButton} style={{ width: '11px' }} />
                            <span className='ps-2'>{!timer ? 'preview' : timer}</span>
                        </button>
                    </div> */}
                    <div className='share-mix-sound-pallete'>
                        <h6 className='title fw-light mb-2'>Sounds</h6>
                        <div className='external-mixer-pallete'>
                            {
                                soundData && soundData.map((sound, index) => (
                                    <div className="mixerSoundDetail py-2" key={index}>
                                        <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                                            <div className="mixerSoundThumbnail mixerStyle">
                                                <img
                                                    src={ sound.tumbnail}
                                                    alt="Thumbnail"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mixerAudioTitle m-0">
                                                {sound.name}
                                            </p>
                                            <div>
                                                {/* <ReactAudioPlayer
                                                            ref={(r) => { el[index] = r; }}
                                                            src={BASEURL + item.ios_patch}
                                                            controls
                                                            autoPlay={false}
                                                            loop={true}
                                                            onListen={(e) => listen(e, index)}
                                                            listenInterval={10}
                                                            onCanPlay={e => isReadyToPlay(e, index)}
                                                        /> */}
                                                <div className='d-none'>
                                                    <audio src={ sound.audio} id={`audio${index}`} controls />
                                                </div>
                                            </div>
                                            <div className="range d-none">
                                                <input type="range" id="Range1" value={rangeValue} onChange={(e) => setRangeValue(e.currentTarget)} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            (audioData && audioData.length > 0) && <div>
                                < h6 className='title fw-light text-capitalize my-2'>{audioData[0].type}</h6>
                                <div className=''>
                                    <div className="mixerSoundDetail py-2">
                                        <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                                            <div className="mixerSoundThumbnail mixerStyle">
                                                <img
                                                    src={ audioData[0].tumbnail}
                                                    alt="Thumbnail"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mixerAudioTitle m-0">
                                                {audioData[0].name}
                                            </p>
                                            <div>
                                                <div className='d-none'>
                                                    <audio src={ audioData[0].audio} id={`audio${mixerData && mixerData.length - 1}`} controls />
                                                </div>
                                            </div>
                                            <div className="range d-none">
                                                <input type="range" id="Range1" value={rangeValue} onChange={(e) => setRangeValue(e.currentTarget)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                    <div className='d-flex justify-content-between gap-4 mt-5 mb-5'>
                        <button style={{ backgroundColor: '#a4a2b0' }} className='w-50 px-4 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0 mx-auto' onClick={startsound}>
                            <img src={play ? PreviewPlayButton : PreviewPauseButton} style={{ width: '11px' }} />
                            <span className='ps-2' style={{ width: '50px' }}>{!pauseTimer ? 'listen' : pauseTimer}</span>
                        </button>
                        {/* <button style={{ backgroundColor: '#ffffff1f' }} className='w-50 px-4 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-grow-1'>
                            <span className='ps-2'>Play in App</span>
                        </button> */}
                    </div>
                </div>
                <div className='text-center mb-5 externalLink justify-content-center'>
                    <Link to={`${BASEURLFrontend}/signup`} className=" ">
                        Create Account
                    </Link>
                    or
                    <Link to={`${BASEURLFrontend}/login`} className="">
                        Login
                    </Link>
                </div>
                <div className='text-center mb-2 opacity-50 pt-5'>
                    <small className='fw-light'>© 2020-2024 Scofa, LLC</small>
                </div>
            </div>
        </div >
    )
}

export default SaveShareMixExternal;